<template>
  <!-- :id="`detailsModal_${resourceId}`" -->
  <b-modal
    id="detailsModal"
    :name="name"
    size="xl"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <span
          >Dettagli - Numero Polizza: {{ number }} -
          {{ modals.find((e) => e.name === selected).text }}</span
        >
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>

    <!-- <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn @click="cancel()" size="sm" variant="outline-secondary"
          >CHIUDI</b-btn
        >
      </b-form-group>
    </template> -->
    <component
      :is="selected"
      :resourceId="resourceId"
      @number="onNumber"
    ></component>
    <!-- <General :resourceId="resourceId"> </General> -->
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      selected: "General",
      number: null,
      modals: [{ name: "General", text: "Generale" }],
    };
  },
  components: {
    // General,
    General: () => import("@/components/wallet/policies/details/General"),
  },
  props: {
    resourceId: null,
    name: null,
  },
  computed: {},
  // mounted() {
  //   this.$refs['myRelation'].firstChild.addEventListener('click', function(event) {
  //     event.preventDefault();
  //     console.log('clicked: ', event.target);
  //   })
  // },
  methods: {
    onNumber(value) {
      this.number = value;
    },
  },
};
</script>
<style lang="scss" scoped></style>
